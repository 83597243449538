import { Observable } from 'rxjs';
import { EmployeeInvitationInfoModel } from '../../03_domain/models/employee-invitation-info-model';
import { EmployeeInvitationModel } from '../../03_domain/models/employee-invitation-model';
import { EmployeeModel } from '../../03_domain/models/employee-model';
import { TenantModel } from '../../03_domain/models/tenant-model';
import { TenantUpdateModel } from '../../03_domain/models/tenant-update-model';

export abstract class TenantUsecases {
  public abstract registerTenant({
    email,
    password,
    username,
    companyName,
  }: {
    email: string;
    password: string;
    username: string;
    companyName: string;
  }): Observable<void>;

  public abstract registerEmployee({
    invitationId,
    username,
    password,
  }: {
    invitationId: string;
    username: string;
    password: string;
  }): Observable<void>;

  public abstract inviteEmployee({
    email,
  }: {
    email: string;
  }): Observable<EmployeeInvitationModel | undefined>;

  public abstract getInvitationInfo({
    invitationId,
  }: {
    invitationId: string;
  }): Observable<EmployeeInvitationInfoModel>;

  public abstract getEmployeeList(): Observable<EmployeeModel[] | undefined>;

  public abstract deleteEmployee({
    employeeId,
  }: {
    employeeId: string;
  }): Observable<void>;

  public abstract getInvitationList(): Observable<
    EmployeeInvitationModel[] | undefined
  >;

  public abstract deleteInvitation({
    invitationId,
  }: {
    invitationId: string;
  }): Observable<void>;

  public abstract getTenantInfo(): Observable<TenantModel>;

  public abstract updateTenantInfo({
    tenantUpdate,
  }: {
    tenantUpdate: TenantUpdateModel;
  }): Observable<void>;
}
