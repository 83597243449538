import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { EmployeeInvitationInfoModel } from '../03_domain/models/employee-invitation-info-model';
import { EmployeeInvitationModel } from '../03_domain/models/employee-invitation-model';
import { EmployeeModel } from '../03_domain/models/employee-model';
import { TenantModel } from '../03_domain/models/tenant-model';
import { TenantUpdateModel } from '../03_domain/models/tenant-update-model';
import { ToastController } from './toast-controller';
import { TenantUsecases } from './usecases/tenant-usecases';

export abstract class TenantController {
  public abstract registerTenant({
    email,
    password,
    username,
    companyName,
  }: {
    email: string;
    password: string;
    username: string;
    companyName: string;
  }): Promise<void>;

  public abstract registerEmployee({
    invitationId,
    username,
    password,
  }: {
    invitationId: string;
    username: string;
    password: string;
  }): Promise<void>;

  public abstract inviteEmployee({
    email,
  }: {
    email: string;
  }): Promise<EmployeeInvitationModel | undefined>;

  public abstract getInvitationInfo({
    invitationId,
  }: {
    invitationId: string;
  }): Promise<EmployeeInvitationInfoModel | undefined>;

  public abstract getEmployeeList(): Promise<EmployeeModel[] | undefined>;

  public abstract deleteEmployee({
    employeeId,
  }: {
    employeeId: string;
  }): Promise<void>;

  public abstract getInvitationList(): Promise<
    EmployeeInvitationModel[] | undefined
  >;

  public abstract deleteInvitation({
    invitationId,
  }: {
    invitationId: string;
  }): Promise<void>;

  public abstract getTenantInfo(): Promise<TenantModel | undefined>;

  public abstract updateTenantInfo({
    tenantUpdate,
  }: {
    tenantUpdate: TenantUpdateModel;
  }): Promise<void>;
}

@Injectable()
export class TenantControllerImpl implements TenantController {
  constructor(
    private toastController: ToastController,
    private tenantUsecases: TenantUsecases
  ) {}
  public async registerTenant({
    email,
    password,
    username,
    companyName,
  }: {
    email: string;
    password: string;
    username: string;
    companyName: string;
  }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.tenantUsecases
        .registerTenant({ email, password, username, companyName })
        .pipe(take(1))
        .subscribe({
          next: _ => {
            resolve();
          },
          error: err => {
            if (err.status === 422 || err.status === 409) {
              this.toastController.showError({
                message: 'Die E-Mail-Adresse ist bereits vergeben.',
              });
            } else {
              this.toastController.showError({
                message: 'Bei der Registrierung ist ein Fehler aufgetreten.',
              });
            }
            reject(err);
          },
        });
    });
  }

  public async registerEmployee({
    invitationId,
    username,
    password,
  }: {
    invitationId: string;
    username: string;
    password: string;
  }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.tenantUsecases
        .registerEmployee({ invitationId, username, password })
        .pipe(take(1))
        .subscribe({
          next: _ => {
            resolve();
          },
          error: err => {
            if (err.status === 422 || err.status === 409) {
              this.toastController.showError({
                message: 'Die E-Mail-Adresse ist bereits vergeben.',
              });
            } else {
              this.toastController.showError({
                message: 'Bei der Registrierung ist ein Fehler aufgetreten.',
              });
            }
            reject(err);
          },
        });
    });
  }

  public async inviteEmployee({
    email,
  }: {
    email: string;
  }): Promise<EmployeeInvitationModel | undefined> {
    return new Promise<EmployeeInvitationModel | undefined>(
      (resolve, reject) => {
        this.tenantUsecases
          .inviteEmployee({ email })
          .pipe(take(1))
          .subscribe({
            next: invitationInfo => {
              this.toastController.showSuccess({
                message: 'Mitarbeiter erfolgreich eingeladen.',
              });
              resolve(invitationInfo);
            },
            error: err => {
              if (err.status === 422 || err.status === 409) {
                this.toastController.showError({
                  message: 'Die E-Mail-Adresse konnte nicht eingeladen werden.',
                });
              } else {
                this.toastController.showError({
                  message:
                    'Beim Einladen des Mitarbeiters ist ein Fehler aufgetreten.',
                });
              }
              console.log(err);
              reject(err);
            },
          });
      }
    );
  }

  public async getInvitationInfo({
    invitationId,
  }: {
    invitationId: string;
  }): Promise<EmployeeInvitationInfoModel> {
    return new Promise<EmployeeInvitationInfoModel>((resolve, reject) => {
      this.tenantUsecases
        .getInvitationInfo({ invitationId })
        .pipe(take(1))
        .subscribe({
          next: info => {
            resolve(info);
          },
          error: err => {
            reject(err);
          },
        });
    });
  }

  public async getEmployeeList(): Promise<EmployeeModel[] | undefined> {
    return new Promise<EmployeeModel[] | undefined>((resolve, reject) => {
      this.tenantUsecases
        .getEmployeeList()
        .pipe(take(1))
        .subscribe({
          next: list => {
            resolve(list);
          },
          error: err => {
            reject(err);
          },
        });
    });
  }

  public async deleteEmployee({
    employeeId,
  }: {
    employeeId: string;
  }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.tenantUsecases
        .deleteEmployee({ employeeId })
        .pipe(take(1))
        .subscribe({
          next: _ => {
            this.toastController.showSuccess({
              message: 'Mitarbeiter erfolgreich gelöscht.',
            });
            resolve();
          },
          error: err => {
            this.toastController.showError({
              message: 'Mitarbeiter konnte nicht gelöscht werden.',
            });
            reject(err);
          },
        });
    });
  }

  public async getInvitationList(): Promise<
    EmployeeInvitationModel[] | undefined
  > {
    return new Promise<EmployeeInvitationModel[] | undefined>(
      (resolve, reject) => {
        this.tenantUsecases
          .getInvitationList()
          .pipe(take(1))
          .subscribe({
            next: list => {
              resolve(list);
            },
            error: err => {
              reject(err);
            },
          });
      }
    );
  }

  public async deleteInvitation({
    invitationId,
  }: {
    invitationId: string;
  }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.tenantUsecases
        .deleteInvitation({ invitationId })
        .pipe(take(1))
        .subscribe({
          next: _ => {
            this.toastController.showSuccess({
              message: 'Einladung erfolgreich gelöscht.',
            });
            resolve();
          },
          error: err => {
            this.toastController.showError({
              message: 'Einladung konnte nicht gelöscht werden.',
            });
            reject(err);
          },
        });
    });
  }

  public async getTenantInfo(): Promise<TenantModel | undefined> {
    return new Promise<TenantModel | undefined>((resolve, reject) => {
      this.tenantUsecases
        .getTenantInfo()
        .pipe(take(1))
        .subscribe({
          next: info => {
            resolve(info);
          },
          error: err => {
            reject(err);
          },
        });
    });
  }

  public async updateTenantInfo({
    tenantUpdate,
  }: {
    tenantUpdate: TenantUpdateModel;
  }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.tenantUsecases
        .updateTenantInfo({ tenantUpdate })
        .pipe(take(1))
        .subscribe({
          next: _ => {
            this.toastController.showSuccess({
              message: 'Daten erfolgreich aktualisiert.',
            });
            resolve();
          },
          error: err => {
            this.toastController.showError({
              message: 'Daten konnten nicht aktualisiert werden.',
            });
            reject(err);
          },
        });
    });
  }
}
